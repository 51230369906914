@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "SFProDisplay";
    src: url("../public/fonts/SFProDisplay/SFPRODISPLAYREGULAR.OTF");
}

@font-face {
    font-family: "Arimo";
    src: url("../public/fonts/Arimo/Arimo-VariableFont_wght.ttf");
}